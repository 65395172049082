import scribe from '@grab-x/scribe-web-sdk'

const ScribeAppName = 'gfb-registration'
export type StateName =
  | 'GFB_LINK_API_FAIL'
  | 'GFB_SIGNUP_PERSONAL'
  | 'GFB_SIGNUP_COMPANY'
  | 'GFB_SIGNUP_UPLOAD'
  | 'GFB_SIGNUP_VERIFY'
  | 'GFB_APPLICATION_DONE'
  | 'GFB_ADMIN_PWD'
  | 'GFB_ADMIN_PHONE'
  | 'GFB_ADMIN_OTP'
  | 'GFB_USER_PHONE'
  | 'GFB_USER_CONNECTED'
  | 'GFB_USER_OTP'
  | 'GFB_USER_PWD'
  | 'GFB_USER_OPEN_GRAB'
  | 'GFB_WEIRD_LINK'
  | 'GFB_USER_LINK_EXPIRED_RESENT'
  | 'GFB_USER_LINK_EXPIRED_INVALID'
  | 'GFB_USER_PHONE'
  | 'SIGNUP_HOME'
  | 'COMPANY_INFO'
  | 'PERSONAL_INFO'
  | 'EMAIL_OTP'
  | 'ACCOUNT_CREATED'
  | 'EXISTING_EMAIL'
  | 'API_WRONG'
  | 'SIGNUP_PURPOSE'
  | 'MEX_REDIRECT'
  | 'EMP_REDIRECT'

export type EventName =
  | 'CLICK_HERE_INSTEAD'
  | 'REACH_OUT_FOR_HELP'
  | 'GET_CODE'
  | 'DEFAULT'
  | 'WORK_EMAIL'
  | 'CONTACT_NUMBER'
  | 'NEXT'
  | 'INCOMPLETE_ERROR'
  | 'BILLING_ADDRESS_INFO'
  | 'BIZ_REG_NUMBER'
  | 'SKIP'
  | 'BILLING_ADDRESS_1'
  | 'PREVIOUS'
  | 'UPLOAD'
  | 'WORNG_FORMAT'
  | 'FILE_TOO_BIG'
  | 'SERVER_ERROR'
  | 'OFFICIAL_DOC_REQUIRED'
  | 'UPLOAD_SUCCESS'
  | 'TOO_MANY_OTP_REQUESTS'
  | 'WRONG_OTP_ERROR'
  | 'WRONG_OTP_THREE_TIMES'
  | 'EMAIL_USED'
  | 'EMPTY_OTP_ERROR'
  | 'VERIFY'
  | 'RESEND_OTP'
  | 'SEND_OTP'
  | 'CHANGE_EMAIL'
  | 'CREATE_PWD'
  | 'EMPTY_PWD'
  | 'WRONG_FORMAT_PWD'
  | 'PWD_FIELD'
  | 'INVALID_LINK'
  | 'GET_VERIFICATION_CODE'
  | 'NUMBER_FIELD'
  | 'EMPTY_NUMBER'
  | 'WRONG_GRAB_NUMBER'
  | 'APP_STORE'
  | 'PLAY_STORE'
  | 'OTP_FIELD'
  | 'EMPTY_OTP'
  | 'WRONG_OTP'
  | 'CHANGE_PHONE'
  | 'OTP_VERIFY_SUCCESS'
  | 'TYPE_OTP'
  | 'CLICK_RESEND'
  | 'CLICK_HELP_CENTER'
  | 'RESEND_EXCEED_LIMIT'
  | 'TYPE_NAME'
  | 'TYPE_PHONE_NUMBER'
  | 'CLICK_CONTINUE'
  | 'TYPE_COMPANY_NAME'
  | 'SELECT_EMPLOYEES_SIZE'
  | 'AGREE_TNC'
  | 'TNC_ERROR'
  | 'CLICK_MERCHANT_LINK'
  | 'CLICK_LOGIN'
  | 'CLICK_SIGN_UP'
  | 'SCROLL_LANDING_PAGE'
  | 'TYPE_EMAIL'
  | 'CONTINUE_ERROR'
  | 'CLICK_REASON'
  | 'CLICK_REDIRECT_SIGNUP'
  | 'CLICK_BP_SIGNUP'

export const initScribe = (): void => {
  const isPrd = window.Grab.config.appEnv === 'PRODUCTION'
  scribe.initialize(ScribeAppName, isPrd)
}

export const sendEvent = (
  stateName: StateName,
  eventName: EventName,
  facets?: { [key: string]: unknown }
): void => {
  try {
    scribe.sendEvent(eventName, { ...facets, STATE_NAME: stateName })
  } catch {
    // ignored
  }
}

export const getGrabXVariable = async (
  featureKey: string,
  countryCode?: string
): Promise<scribe.ScribeSDKResponse | undefined> => {
  const predefinedFacetsBuilder = scribe.getPredefinedFacetsBuilder()
  const predefinedFacets = predefinedFacetsBuilder().country(countryCode).buildFacets()
  return scribe
    .getVariable(featureKey, predefinedFacets)
    .then((result) => {
      // eslint-disable-next-line unicorn/no-useless-undefined
      if (result && typeof result.value !== undefined) {
        return result
      }
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined
    })
    .catch(() => {
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined
    })
}
